module.exports = {
  siteMetadata: {
    title: `Bezirksregierung Münster`,
    description: ``,
    author: ``,
    siteUrl: `https://www.bezreg-muenster.de`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-image`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Toolbox Innenstadt`,
        short_name: `Toolbox`,
        start_url: `/`,
        background_color: `#fff`,
        // This will impact how browsers show your PWA/website
        // https://css-tricks.com/meta-theme-color-and-trickery/
        // theme_color: `#663399`,
        display: `minimal-ui`,
        icon: `src/images/app-icon.png`, // This path is relative to the root of the site.
      },
    },
    {
      resolve: "gatsby-plugin-sass",
      options: {
        useResolveUrlLoader: true,
      },
    },
    {
      resolve: `gatsby-plugin-webfonts`,
      options: {
        fonts: {
          google: [
            {
              family: `Barlow Condensed`,
              variants: [`400`],
              strategy: `selfHosted`
            },
            {
              family: `Open Sans`,
              variants: [`400`, `600`],
              strategy: `selfHosted`
            },
            {
              family: `Material Icons`,
              strategy: `selfHosted`
            }
          ],
        }
      },
    },
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'gkykXjrWIBvd0AEnX3sGiQtt',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
        resolveRelations: ['topicList.topic1', 'topicList.topic2', 'topicList.topic3', 'topicList.topic4', 'topicList.topic5', 'topicList.topic6', 'topicList.topic7', 'topicList.topic8', 'topicList.topic9', 'topicList.topic10', 'topicList.topic11', 'topicList.topic12', 'topicList.topic13'],
        localAssets: true, // Optional parameter to download the images to use with Gatsby Image Plugin
        // languages: ['de', 'at'] // Optional parameter. Omission will retrieve all languages by default.
      }
    },
    {
      resolve: `gatsby-plugin-react-redux-persist`,
      options: {
        pathToCreateStoreModule: './src/state/createStore'
      }
    }
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}


